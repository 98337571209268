import "whatwg-fetch";

/**
 * The options to pass to a fetch request
 */
export type FetchOptions = {
  headers?: Record<string, string>;
  method?: string;
  timeout?: number;
  body?: string;
  credentials?: RequestCredentials;
};

/**
 * Executes a fetch request
 * @param url   the url of the request
 * @param options   the options of the request
 */
export const fetchRequest = async (url: string, options: FetchOptions) => {
  if (options.timeout) {
    return fetchRequestWithTimeout(url, options);
  }

  const response = await fetch(url, options);

  return {
    status: response.status,
    json: await response.json(),
  };
};

/**
 * Execute a fetch request with a timeout
 * @param url   the url of the request
 * @param options   the fetch options
 */
const fetchRequestWithTimeout = async (url: string, options: FetchOptions) => {
  let timeoutId;

  try {
    const controller = new AbortController();
    timeoutId = setTimeout(() => controller.abort(), options.timeout);
    const response = await fetch(url, {
      ...options,
      signal: controller.signal,
    });

    return {
      status: response.status,
      json:
        response.headers.get("content-type") === "application/json"
          ? await response.json()
          : null,
    };
  } finally {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  }
};

export const createFormBody = (bodyObj: Record<string, any>) => {
  const formBody: string[] = [];
  Object.keys(bodyObj).forEach((property) => {
    if (bodyObj[property]) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(bodyObj[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
  });

  if (formBody.length > 0) {
    return formBody.join("&");
  }

  return "";
};
