import { IdToken } from "../../types/auth";
import { Uid2Identity } from "../../types/uid2Types";

export function getUid2IdentityFromIdToken(
  idToken: IdToken
): Uid2Identity | undefined {
  if (
    idToken &&
    idToken.uid2_at &&
    idToken.uid2_rt &&
    idToken.uid2_iexp &&
    idToken.uid2_rfrom &&
    idToken.uid2_rexp &&
    idToken.uid2_rkey
  ) {
    return {
      advertising_token: idToken.uid2_at,
      refresh_token: idToken.uid2_rt,
      identity_expires: idToken.uid2_iexp,
      refresh_from: idToken.uid2_rfrom,
      refresh_expires: idToken.uid2_rexp,
      refresh_response_key: idToken.uid2_rkey,
    };
  }

  return undefined;
}
