export function isBrowser() {
  return typeof window !== "undefined" && typeof document !== "undefined";
}

export const isPostMessageSupported = () => {
  return (
    isBrowser() &&
    typeof window.postMessage !== undefined &&
    window.postMessage !== null
  );
};
