import uid2sdk from "../../../lib/uid2-sdk-2.0.0";
import { config } from "../../config";
import {
  IdentityResponse,
  Uid2Identity,
  Uid2Options,
} from "../../types/uid2Types";
import { SdkError } from "../error/errors";
import { createUid2Sdk } from "./create-uid2-sdk";

let uid2: uid2sdk.UID2;

/**
 * Initializes the uid2 SDK
 * @param options  the Uid2 Sdk Options
 * @param callback callback function to receive uid2 events
 */
const initialize = (
  options: Uid2Options,
  callback?: (response: IdentityResponse) => void
) => {
  if (uid2) {
    /**
     * we re-initialize the uid2 sdk, as after every successful login we need to pass the advertising token,
     * and the uid2 sdk prevents calling init more than once
     *
     */
    uid2.disconnect();
  }

  uid2 = createUid2Sdk();

  uid2.init({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    callback: callback ? callback : () => {},
    identity: options.identity,
    baseUrl: options.baseUrl,
  });
};

/**
 * The isLoginRequired function provided by the uid2 sdk returns true, if it's determined that
 * a valid advertising token is not available and a new one should be generated. This function returns
 * false if a valid identity exists, or it can be refreshed via a refresh token.
 */
const isLoginRequired = () => uid2.isLoginRequired();

const isOptedOut = (status: number): boolean => {
  const UID2_OPTED_OUT_STATUS = -4;

  return status === UID2_OPTED_OUT_STATUS;
};

/**
 * returns the uid2 advertising token or undefined if it does not exist
 */
const getAdvertisingToken = (): string | undefined => {
  return uid2.getAdvertisingToken();
};

/**
 * Deletes the uid2 cookie which effectively ends the uid2 session in the browser
 */
const logout = (): void => {
  uid2.disconnect();
};

/**
 * load the uid2 identity from the uid2 token
 * @param uid2Token     the uid2 token
 * @param uid2BaseUrl   the uid2 service base url
 */
const setIdentity = (uid2Token: Uid2Identity, uid2BaseUrl?: string) => {
  let timeoutId: number | undefined;

  const identity = new Promise(
    (resolve: (response: IdentityResponse) => void) => {
      initialize(
        {
          baseUrl: uid2BaseUrl,
          identity: uid2Token,
        },
        (response: IdentityResponse) => resolve(response)
      );
    }
  );

  const fallback = new Promise((_, reject) => {
    timeoutId = setTimeout(() => {
      reject(new SdkError("Unable to set uid2 identity"));
    }, config.API_DEFAULT_TIMEOUT);
  });

  return Promise.race([identity, fallback]).finally(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  });
};

export {
  IdentityResponse,
  initialize,
  getAdvertisingToken,
  setIdentity,
  logout,
  isLoginRequired,
  isOptedOut,
};
