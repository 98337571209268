import {
  checkOpenpassLocalCookieExists,
  createOpenpassLocalCookie,
  deleteOpenpassLocalCookie,
} from "../common/cookie";

export function isOpenPassSignInRequired() {
  return checkOpenpassLocalCookieExists();
}

export function openPassSignOut() {
  deleteOpenpassLocalCookie();
}

export function openPassSetSignedIn() {
  createOpenpassLocalCookie();
}
