import { generateRandomString, hashSha256 } from "../crypto/crypto";
import { arrayBufferAsString } from "../common/buffer";
import { base64Encode } from "../crypto/base64";

export const generateCodeVerifier = (length = 43) => {
  const verifier = generateRandomString(length);
  return verifier;
};

export const generateCodeChallenge = async (verifier: string) => {
  const hash = await hashSha256(verifier);
  return base64Encode(arrayBufferAsString(hash));
};
