import { LoginClientState } from "../../types/login";

export class SdkError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class AuthError extends SdkError {
  constructor(
    public error: string,
    public errorDescription: string,
    public errorUi: string,
    public clientState?: LoginClientState
  ) {
    super(errorDescription || error);
  }
}

export class AuthCancelledError extends SdkError {
  constructor(message: string) {
    super(message);
  }
}
