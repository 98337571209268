export class HttpResponse<D> {
  public readonly status: number;
  public data?: D;

  constructor(status: number, data: D) {
    this.status = status;
    this.data = data;
  }

  public isOk() {
    return this.status >= 200 && this.status < 300;
  }
}
