import StorageProvider from "./provider";

/**
 * Browser session storage provider
 */
export default class StorageSessionProvider implements StorageProvider {
  set(key: string, value: string): void {
    sessionStorage.setItem(key, value);
  }

  get(key: string, defaultValue: string): string {
    const value = sessionStorage.getItem(key);

    if (!value) {
      return defaultValue;
    }

    return value;
  }

  remove(key: string): void {
    sessionStorage.removeItem(key);
  }
}
