import { config, getSSOBaseUrl } from "./config";
import {
  PARAM_CODE_CHALLENGE_METHOD_VALUE,
  PARAM_CODE_RESPONSE_MODE_MESSAGE,
  POPUP_MESSAGE_SOURCE,
} from "./constants";
import { generateCodeChallenge, generateCodeVerifier } from "./libs/auth/pkce";
import { generateStateValue } from "./libs/auth/state";
import { buildAuthorizeUrl } from "./libs/auth/url";
import {
  OpenPassApiClient,
  OpenPassTokens,
} from "./libs/client/openPassApiClient";
import { isPostMessageSupported } from "./libs/common/browser";
import {
  isLargeBreakpoint,
  windowOpenPopupWithPercentage,
  windowOpenPopup,
} from "./libs/common/window";
import { ERROR_CODE_INVALID_AUTH_CODE } from "./libs/error/codes";
import { AuthCancelledError, AuthError, SdkError } from "./libs/error/errors";
import {
  ERROR_MESSAGE_INVALID_AUTH_CODE_RESPONSE,
  ERROR_MESSAGE_POPUP_CLOSED,
  ERROR_MESSAGE_POPUP_TIMEOUT,
  ERROR_MESSAGE_SET_UID2_IDENTITY,
  ERROR_POPUP_FAILED_TO_OPEN,
  ERROR_POPUP_REOPENED,
} from "./libs/error/messages";
import RedirectAuth from "./redirect";
import { AuthCodeMessageResponse, AuthSession, IdToken } from "./types/auth";
import { LoginResponse, PopupLoginOptions } from "./types/login";
import { OpenPassAuthOptions } from "./types/options";
import { AbortablePromise } from "./libs/promise/abortable";
import { getUid2IdentityFromIdToken } from "./libs/uid2/uid2Identity";
import AuthenticationManager from "./libs/manager/authManager";

const POPUP_HEIGHT = 512;
const POPUP_WIDTH = 860;
const POPUP_NAME = "openpass:popup:login";

// Used to close the popup in the event the set authenticated method requires a remote API call to complete the authentication
// process, and that call suffers from increased latency.
const SET_AUTHENTICATED_TIMEOUT_IN_MS = 10000;

/**
 * Class which handles the Popup authorization flow.
 * This class redirects to the authorization server and waits for a response from the authorization server.
 * The response is sent via the window.postMessage API to support secure cross-origin communication.
 */
export default class PopupAuth {
  private readonly redirectApi: RedirectAuth;
  private readonly openPassClient: OpenPassApiClient;
  private readonly openPassAuthOptions: OpenPassAuthOptions;
  private readonly authenticationManager: AuthenticationManager;
  private promises: AbortablePromise<any>[];

  constructor(
    openPassAuthOptions: OpenPassAuthOptions,
    redirectApi: RedirectAuth,
    authenticationManager: AuthenticationManager,
    openPassClient: OpenPassApiClient
  ) {
    this.openPassAuthOptions = openPassAuthOptions;
    this.openPassClient = openPassClient;
    this.redirectApi = redirectApi;
    this.authenticationManager = authenticationManager;
    this.promises = [];
  }

  /**
   * Launch a popup window and redirect to the authorization service in the popup.
   * @param options the login options. These options are used to support fallback to the redirect authorization flow.
   */
  public async login(options?: PopupLoginOptions): Promise<LoginResponse> {
    //Since the popup window is reused and in the event login is invoked again while the popup window is open let's do any necessary cleanup.
    this.cleanUpIfNecessary();

    if (options && options.fallbackToRedirect) {
      return this.loginWithRedirectFallback(options);
    }

    const popupWindow = this.openPopup(options);

    if (!popupWindow) {
      throw new SdkError(ERROR_POPUP_FAILED_TO_OPEN);
    }

    popupWindow.focus();

    return this.doLogin(popupWindow, options);
  }

  async loginWithRedirectFallback(
    options: PopupLoginOptions
  ): Promise<LoginResponse> {
    if (!options.redirectUrl) {
      throw new SdkError("redirectUrl is required");
    }

    let popupWindow: Window | null;
    const loginRedirectOptions = {
      redirectUrl: options.redirectUrl,
      clientState: options.clientState,
    };

    try {
      popupWindow = this.openPopup(options);

      if (!popupWindow) {
        //fallback to full redirect
        await this.redirectApi.login(loginRedirectOptions);

        // Note: we'll never get here because the page would already be redirect to the sign-in form
        return Promise.reject(
          new SdkError(
            "Using redirect instead of popup. This error should not be thrown because the redirect happens first."
          )
        );
      }

      popupWindow.focus();
    } catch (e) {
      console.warn(e);
      //fallback to full redirect
      await this.redirectApi.login(loginRedirectOptions);

      // Note: we'll never get here because the page would already be redirect to the sign-in form
      return Promise.reject(
        new SdkError(
          "Using redirect instead of popup. This error should not be thrown because the redirect happens first."
        )
      );
    }

    return this.doLogin(popupWindow, options);
  }

  private async doLogin(
    popupWindow: Window,
    options?: PopupLoginOptions
  ): Promise<LoginResponse> {
    const popupCloseHandler = (e: Event) => {
      this.closePopup(window);
    };

    window.addEventListener("beforeunload", popupCloseHandler);

    try {
      const verifier = generateCodeVerifier();

      const authSession: AuthSession = {
        clientState:
          options && options.clientState ? options.clientState : undefined,
        clientId: this.openPassAuthOptions.clientId,
        redirectUrl: options && options.redirectUrl ? options.redirectUrl : "",
        codeVerifier: verifier,
        codeChallenge: await generateCodeChallenge(verifier),
        codeChallengeMethod: PARAM_CODE_CHALLENGE_METHOD_VALUE,
        state: generateStateValue(),
        responseMode: PARAM_CODE_RESPONSE_MODE_MESSAGE,
      };

      const loginUri = buildAuthorizeUrl(
        getSSOBaseUrl(this.openPassAuthOptions.baseUrl),
        config.SSO_AUTHORIZE_PATH,
        authSession
      );
      popupWindow.location.replace(loginUri);

      return await this.waitForPopupResponse(popupWindow, authSession);
    } catch (e) {
      if (!(e instanceof AuthCancelledError)) {
        this.closePopup(popupWindow);
      }
      throw e;
    } finally {
      window.removeEventListener("beforeunload", popupCloseHandler);
    }
  }

  private async waitForPopupResponse(
    popupWindow: Window,
    authSession: AuthSession
  ): Promise<LoginResponse> {
    const authCodeResponse = await this.listenForPopupResponse(popupWindow);

    if (
      !this.isAuthCodeValid(authCodeResponse, authSession) ||
      !authCodeResponse.code
    ) {
      throw new AuthError(
        authCodeResponse.error
          ? authCodeResponse.error
          : ERROR_CODE_INVALID_AUTH_CODE,
        authCodeResponse.errorDescription
          ? authCodeResponse.errorDescription
          : ERROR_MESSAGE_INVALID_AUTH_CODE_RESPONSE,
        authCodeResponse.errorUri ? authCodeResponse.errorUri : "",
        authSession.clientState
      );
    }

    const tokens = await this.openPassClient.finalizeAuthenticationAndGetTokens(
      authCodeResponse.code,
      authSession
    );

    return await this.completeAuthentication(tokens, authSession, popupWindow);
  }

  protected async completeAuthentication(
    tokens: OpenPassTokens,
    authSession: AuthSession,
    popupWindow: Window
  ): Promise<LoginResponse> {
    const { idToken, rawIdToken } = tokens;

    return new Promise<LoginResponse>((resolve, reject) => {
      //wait for identity to be set before closing the popup
      setTimeout(() => {
        reject(new SdkError(ERROR_MESSAGE_SET_UID2_IDENTITY));
      }, SET_AUTHENTICATED_TIMEOUT_IN_MS);

      (async () => {
        try {
          const session = await this.authenticationManager.setAuthenticated(
            idToken
          );
          const uid2Identity = getUid2IdentityFromIdToken(idToken);
          this.closePopup(popupWindow);
          resolve({
            authenticated: session.authenticated,
            uid2Identity,
            email: idToken.sub,
            clientState: authSession.clientState,
            idToken: idToken,
            rawIdToken: rawIdToken,
          });
        } catch (e) {
          this.closePopup(popupWindow);
          reject(e);
        }
      })();
    });
  }

  private async listenForPopupResponse(popupWindow: Window) {
    let closeTimeout: number | undefined;
    let messageTimeout: number | undefined;
    let messageHandler: (event: MessageEvent) => void;

    const popupPromise = new AbortablePromise<AuthCodeMessageResponse>(
      (resolve, reject, onAbort) => {
        closeTimeout = setInterval(() => {
          if (popupWindow && popupWindow.closed) {
            clearInterval(closeTimeout);
            window.removeEventListener("message", messageHandler);
            this.closePopup(popupWindow);
            reject(new SdkError(ERROR_MESSAGE_POPUP_CLOSED));
          }
        }, 100);

        messageHandler = (event: MessageEvent) => {
          //Check that the message is from the authorization server
          if (
            !this.matchEventOrigin(
              event.origin,
              getSSOBaseUrl(this.openPassAuthOptions.baseUrl)
            ) ||
            !event.data
          ) {
            return;
          }

          const { data } = event;

          if (!data.source || data.source !== POPUP_MESSAGE_SOURCE) {
            return;
          }

          resolve(data as AuthCodeMessageResponse);
        };

        window.addEventListener("message", messageHandler, false);

        messageTimeout = setInterval(() => {
          clearInterval(messageTimeout);
          reject(new SdkError(ERROR_MESSAGE_POPUP_TIMEOUT));
        }, config.POPUP_RESPONSE_TIMEOUT);

        onAbort(() => {
          clearInterval(closeTimeout);
          clearTimeout(messageTimeout);
          window.removeEventListener("message", messageHandler);
          reject(new AuthCancelledError(ERROR_POPUP_REOPENED));
        });
      }
    );

    this.promises.push(popupPromise);

    return popupPromise.finally(() => {
      clearInterval(closeTimeout);
      clearTimeout(messageTimeout);
      window.removeEventListener("message", messageHandler);
    });
  }

  private openPopup(options?: PopupLoginOptions): Window | null {
    if (options && options.fallbackToRedirect) {
      return this.openPopupWithFallback();
    }

    if (isLargeBreakpoint()) {
      return windowOpenPopup("", POPUP_NAME, POPUP_WIDTH, POPUP_HEIGHT);
    } else {
      return windowOpenPopupWithPercentage("", POPUP_NAME, 100);
    }
  }

  private openPopupWithFallback(): Window | null {
    if (!isLargeBreakpoint() || !isPostMessageSupported()) {
      return null;
    }

    return windowOpenPopup("", POPUP_NAME, POPUP_WIDTH, POPUP_HEIGHT);
  }

  private isAuthCodeValid(
    response: AuthCodeMessageResponse,
    authSession: AuthSession
  ): boolean {
    const hasCodeAndStateParams = response.code && response.state;

    if (!hasCodeAndStateParams) {
      return false;
    }

    return authSession.state === response.state;
  }

  private closePopup(window: Window | null) {
    this.promises = [];
    if (!window || window.closed) {
      return;
    }

    window.close();
  }

  private matchEventOrigin(eventOrigin: string, origin: string) {
    if (eventOrigin === origin) {
      return true;
    }

    if (origin.endsWith("/") && origin.length > 1) {
      return eventOrigin === origin.substring(0, origin.length - 1);
    }

    return false;
  }

  private cleanUpIfNecessary() {
    this.promises.forEach((p) => p.abort());
    this.promises = [];
  }
}
