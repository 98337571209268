import { OpenPassAuthOptions } from "../../types/options";
import { OpenPassApiClient } from "../client/openPassApiClient";
import { AuthResponse, IdToken } from "../../types/auth";
import { getUid2IdentityFromIdToken } from "../uid2/uid2Identity";
import { SdkError } from "../error/errors";
import { ERROR_MESSAGE_INVALID_UID2_INTEGRATION_OPTIONS } from "../error/messages";
import { IdentityResponse, Uid2Identity } from "../../types/uid2Types";
import * as uid2 from "../uid2/uid2";
import {
  isOpenPassSignInRequired,
  openPassSignOut,
  openPassSetSignedIn,
} from "../session/session";
import AuthenticationManager from "./authManager";

/**
 * An Authentication manager that manages authentication state when uid2 integration is enabled.
 */
export default class UID2AuthenticationManager
  implements AuthenticationManager
{
  private readonly options: OpenPassAuthOptions;
  private readonly openPassClient: OpenPassApiClient;

  constructor(options: OpenPassAuthOptions, openPassClient: OpenPassApiClient) {
    this.options = options;
    this.openPassClient = openPassClient;
    this.initializeUid2();
  }

  /**
   * Returns an authentication response that can be checked to determine if the current session is authenticated. The authentication
   * state is managed by an OpenPass first party local cookie. The existence of this cookie, is also dependent on the validity of the uid2 identity.
   * @see synchronizeAuthStateWithUid2
   */
  isAuthenticated(): AuthResponse {
    const isAuthenticated = isOpenPassSignInRequired();

    return {
      authenticated: isAuthenticated,
    };
  }

  isAuthenticatedAsync(): Promise<AuthResponse> {
    return Promise.resolve(this.isAuthenticated());
  }

  /**
   * Terminates the session by logging the user out by deleting the OpenPass first party local session cookie, and
   * deleting the uid2 cookie in the browser via a call to the uid2 sdk.
   */
  logout(): void {
    openPassSignOut();
    uid2.logout(); // delete the uid2 identity from the browser
  }

  /**
   * This method will attempt to extract the uid2 token from the id token and initialize the uid2 sdk.
   * If the uid2 token is valid, we consider authentication successful.
   * @param idToken the id token containing a valid uid2 identity token
   */
  async setAuthenticated(idToken: IdToken): Promise<AuthResponse> {
    const uid2Identity = getUid2IdentityFromIdToken(idToken);

    if (!uid2Identity) {
      // if uid2 token generation is disabled in client settings of the OpenPass API throw an error
      throw new SdkError(ERROR_MESSAGE_INVALID_UID2_INTEGRATION_OPTIONS);
    }

    await uid2.setIdentity(uid2Identity, this.options.uid2BaseUrl);

    if (!uid2.isLoginRequired()) {
      // we can consider authentication to be successful as a valid uid2 token exists
      openPassSetSignedIn();
    }

    // reinitialize the uid2 sdk to configure uid2 refresh...
    this.initializeUid2(uid2Identity);

    return this.isAuthenticatedAsync();
  }

  /**
   * Initialize the uid2 sdk
   * @param identity  if passed will use the given uid2 identity otherwise,
   * the uid2 sdk will use the uid2 cookie if available
   * @private
   */
  private initializeUid2(identity?: Uid2Identity) {
    uid2.initialize(
      {
        baseUrl: this.options.uid2BaseUrl,
        identity,
      },
      (response) => this.handleUid2AuthChange(response)
    );
  }

  /**
   * This handler is called in response to initializing the uid2 sdk. This typically occurs on initial page load after the uid2 sdk
   * validates the uid2 identity. It is also called when the uid2 sdk detects a change to the uid2 identity. This change could be the result
   * of an opt-out, refreshing of the uid2 advertising token etc.
   * Note, this handler is only called by the uid2 sdk when it detects a change to the uid2 advertising token.
   *
   * @param uid2AuthState the current authentication status, as determined by the uid2 sdk.
   * @private
   */
  private handleUid2AuthChange(uid2AuthState: IdentityResponse) {
    if (uid2.isOptedOut(uid2AuthState.status)) {
      try {
        this.openPassClient.optOutOfOpenpass();
      } catch (error) {
        console.warn("Failure notifying opt out to OpenPass API", error);
      }
    }

    this.synchronizeAuthStateWithUid2();

    if (this.options.onAuthChange) {
      this.options.onAuthChange(this.isAuthenticated());
    }
  }

  /**
   * Synchronize the uid2 sdk with the OpenPass local session
   * @private
   */
  private synchronizeAuthStateWithUid2() {
    const authResponse = this.isAuthenticated();

    if (uid2.isLoginRequired() && authResponse.authenticated) {
      // a valid uid2 token does not exist and a new one must be generated, so ensure the user is not signed in
      openPassSignOut();
    } else if (!authResponse.authenticated) {
      // removes the uid2 local cookie from the browser, if the user is not signed in to OpenPass
      uid2.logout();
    }
  }
}
