import { OpenPassApiClient } from "./libs/client/openPassApiClient";
import StorageSessionProvider from "./libs/storage/session";
import PopupAuth from "./popup";
import RedirectAuth from "./redirect";
import { OpenPassAuthApi } from "./types/api";
import { LoginOptions, LoginResponse, PopupLoginOptions } from "./types/login";
import { OpenPassAuthOptions } from "./types/options";
import { initOpenPassOptions } from "./libs/options/openpass";
import AuthenticationManager from "./libs/manager/authManager";
import getAuthenticationManager from "./libs/manager/getAuthManager";
import { SignInStateManager } from "./libs/manager/signInStateManager";

/**
 * The OpenPass authorization client API
 */
export default class OpenPassAuth implements OpenPassAuthApi {
  private openPassOptions: OpenPassAuthOptions;
  private readonly redirect: RedirectAuth;
  private readonly popup: PopupAuth;
  private readonly openPassClient: OpenPassApiClient;
  private readonly authenticationManager: AuthenticationManager;

  /**
   * Creates a new instance client with the given options
   * @param options   the options to initialize the client
   */
  public constructor(options: OpenPassAuthOptions) {
    this.openPassOptions = initOpenPassOptions(options);
    this.openPassClient = new OpenPassApiClient(this.openPassOptions);
    this.authenticationManager = getAuthenticationManager(
      this.openPassOptions,
      this.openPassClient
    );
    const openPassApiClient = new OpenPassApiClient(this.openPassOptions);

    this.redirect = new RedirectAuth(
      this.openPassOptions,
      new SignInStateManager(new StorageSessionProvider()),
      this.authenticationManager,
      openPassApiClient
    );
    this.popup = new PopupAuth(
      this.openPassOptions,
      this.redirect,
      this.authenticationManager,
      openPassApiClient
    );
  }

  /**
   * Determines if the user is authenticated
   */
  public async isAuthenticated() {
    return this.authenticationManager.isAuthenticated();
  }

  /**
   * Begins the login redirect flow
   * @param options   the login options
   */
  public async signInWithRedirect(options: LoginOptions) {
    return this.redirect.login(options);
  }

  /**
   * Begin's the login popup flow
   * @param options
   */
  public async signInWithPopup(
    options?: PopupLoginOptions
  ): Promise<LoginResponse> {
    return this.popup.login(options);
  }

  /**
   * Determines if an authentication attempt via a redirect is currently in progress, and that the current browser url
   * meets the conditions to consider it as callback from the authorization server.
   */
  public isRedirect() {
    return this.redirect.isRedirect();
  }

  /**
   * Determines if the current browser url meets the conditions to consider it as a valid redirection from the
   * authorization server
   *
   * @param redirectUrl   the clients redirect url
   */
  public isRedirectUrl(redirectUrl: string) {
    return this.redirect.isRedirectUrl(redirectUrl);
  }

  /**
   * Attempts to complete a login in response to a redirect
   */
  public async handleRedirect() {
    return this.redirect.handleRedirect();
  }

  /**
   * Terminates a session and redirects to given URL when present
   */
  public signOut(signOutUrl?: string) {
    this.authenticationManager.logout();

    if (signOutUrl) {
      window.location.href = signOutUrl;
    }
  }
}
