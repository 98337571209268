export const PARAM_STATE = "state";
export const PARAM_REDIRECT_URI = "redirect_uri";
export const PARAM_RESPONSE_TYPE = "response_type";
export const PARAM_CLIENT_ID = "client_id";
export const PARAM_CODE_CHALLENGE = "code_challenge";
export const PARAM_CODE_CHALLENGE_METHOD = "code_challenge_method";
export const PARAM_CODE_CHALLENGE_METHOD_VALUE = "S256";
export const PARAM_RESPONSE_TYPE_VALUE = "code";
export const PARAM_SCOPE = "scope";
export const PARAM_SCOPE_VALUE = "openid";
export const PARAM_CODE = "code";
export const PARAM_GRANT_TYPE_VALUE = "authorization_code";
export const PARAM_ERROR = "error";
export const PARAM_ERROR_DESCRIPTION = "error_description";
export const PARAM_ERROR_URI = "error_uri";
export const PARAM_CODE_RESPONSE_MODE = "response_mode";
export const PARAM_CODE_RESPONSE_MODE_MESSAGE = "post_message";
export const POPUP_MESSAGE_SOURCE = "openpass-authorize-message";
