import { config } from "../../config";

const COOKIE_NAME = "__openpass-local";
const COOKIE_ATTRIBUTES = "Path=/; SameSite=Lax; Secure";

export const createOpenpassLocalCookie = () => {
  const expiryDate = new Date();
  expiryDate.setDate(
    expiryDate.getDate() + config.OPENPASS_LOCAL_COOKIE_EXPIRY_DAYS
  );
  document.cookie = `${COOKIE_NAME}=true; expires=${expiryDate.toUTCString()} ${COOKIE_ATTRIBUTES}`;
};

export const deleteOpenpassLocalCookie = () => {
  document.cookie = `${COOKIE_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; ${COOKIE_ATTRIBUTES}`;
};

export const checkOpenpassLocalCookieExists = () => {
  return document.cookie.includes(COOKIE_NAME);
};
