const cryptoApi = () => window.crypto || ((window as any).msCrypto as Crypto);

const cryptoSubtleApi = () => {
  const crypto = cryptoApi();
  return crypto.subtle || (crypto as any).webkitSubtle;
};

export const generateRandomString = (length = 43): string => {
  const array = new Uint32Array(length);
  cryptoApi().getRandomValues(array);
  return Array.from(array, (item) => `0${item.toString(16)}`.slice(-2)).join(
    ""
  );
};

export const hashSha256 = async (value: string) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(value);
  const buffer = await cryptoSubtleApi().digest("SHA-256", data);
  return buffer;
};
