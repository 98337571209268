import { AuthRedirectUrlParams, AuthSession } from "../../types/auth";

import {
  PARAM_CLIENT_ID,
  PARAM_CODE,
  PARAM_CODE_CHALLENGE,
  PARAM_CODE_CHALLENGE_METHOD,
  PARAM_CODE_RESPONSE_MODE,
  PARAM_CODE_RESPONSE_MODE_MESSAGE,
  PARAM_ERROR,
  PARAM_ERROR_DESCRIPTION,
  PARAM_ERROR_URI,
  PARAM_REDIRECT_URI,
  PARAM_RESPONSE_TYPE,
  PARAM_RESPONSE_TYPE_VALUE,
  PARAM_SCOPE,
  PARAM_SCOPE_VALUE,
  PARAM_STATE,
} from "../../constants";
import { joinPaths } from "../common/path";

export const parseAuthRedirectUrlParams = (
  queryString: string
): AuthRedirectUrlParams => {
  const params = new URLSearchParams(queryString);
  return {
    code: getParamValue(PARAM_CODE, params),
    state: getParamValue(PARAM_STATE, params),
    error: getParamValue(PARAM_ERROR, params),
    errorDescription: getParamValue(PARAM_ERROR_DESCRIPTION, params),
    errorUri: getParamValue(PARAM_ERROR_URI, params),
  };
};

export const isAuthRedirectUrl = (
  url: string,
  urlParams: AuthRedirectUrlParams,
  redirectUrl: string
): boolean => {
  if (!url || !redirectUrl) {
    return false;
  }

  const urlContainsRedirectUrl =
    url.toLowerCase().indexOf(redirectUrl.toLowerCase()) != -1;
  const hasStateAndCodeParams =
    urlParams.state !== null && urlParams.code !== null;
  const hasErrorParam =
    urlParams.error !== null || urlParams.errorDescription !== null;
  const hasRedirectParams = hasStateAndCodeParams || hasErrorParam;

  return urlContainsRedirectUrl && hasRedirectParams;
};

export const buildAuthorizeUrl = (
  baseUrl: string,
  authorizeEndpoint: string,
  session: AuthSession
) => {
  const responseTypeParam = `${PARAM_RESPONSE_TYPE}=${PARAM_RESPONSE_TYPE_VALUE}`;
  const clientIdParam = `${PARAM_CLIENT_ID}=${session.clientId}`;
  const redirectParam = session.redirectUrl
    ? `${PARAM_REDIRECT_URI}=${session.redirectUrl}`
    : "";
  const scopeParam = `${PARAM_SCOPE}=${PARAM_SCOPE_VALUE}`;
  const stateParam = `${PARAM_STATE}=${session.state}`;
  const challengeMethodParam = `${PARAM_CODE_CHALLENGE_METHOD}=${session.codeChallengeMethod}`;
  const challengeCodeParam = `${PARAM_CODE_CHALLENGE}=${session.codeChallenge}`;
  const responseMode = session.responseMode
    ? `${PARAM_CODE_RESPONSE_MODE}=${session.responseMode}`
    : "";

  let loginUri = `${joinPaths([
    baseUrl,
    authorizeEndpoint,
  ])}?${responseTypeParam}&${clientIdParam}&${scopeParam}&${stateParam}&${challengeMethodParam}&${challengeCodeParam}`;
  loginUri = redirectParam ? `${loginUri}&${redirectParam}` : loginUri;
  loginUri = responseMode ? `${loginUri}&${responseMode}` : loginUri;
  return loginUri;
};

const getParamValue = (name: string, params: URLSearchParams) => {
  const paramValue = params.get(name);

  if (!paramValue) {
    return null;
  }

  return decodeURIComponent(paramValue);
};
