import jwt_decode from "jwt-decode";
import { joinPaths } from "../common/path";
import {
  config,
  getParamGrantTypeValue,
  getApiDefaultTimeout,
} from "../../config";
import { OpenPassAuthOptions } from "../../types/options";
import { fetchRequest } from "../fetch/fetch";
import { HttpResponse } from "./http";
import { AuthSession, IdToken } from "types/auth";
import {
  ERROR_CODE_JWT_DECODE,
  ERROR_CODE_TOKEN_REQUEST_FAILED,
} from "../error/codes";
import { AuthError } from "../error/errors";
import {
  ERROR_MESSAGE_DECODE_JWT,
  ERROR_MESSAGE_TOKEN_REQUEST_FAILED,
} from "../error/messages";
import { createFormBody } from "../fetch/fetch";

export interface TokenResponse {
  id_token?: string;
  access_token?: string;
  error?: string;
  error_description?: string;
  error_uri?: string;
  state?: string;
}

export interface TokenRequest {
  grant_type: string;
  redirect_uri: string;
  client_id: string;
  code: string;
  code_verifier: string;
}

export interface OpenPassTokens {
  idToken: IdToken;
  rawIdToken: string;
}

/**
 * Holds all methods that call the OpenPass API
 */
export class OpenPassApiClient {
  private options: OpenPassAuthOptions;

  constructor(options: OpenPassAuthOptions) {
    this.options = options;
  }

  public async finalizeAuthenticationAndGetTokens(
    authCode: string,
    authSession: AuthSession
  ): Promise<OpenPassTokens> {
    const reqBody = {
      grant_type: getParamGrantTypeValue(),
      client_id: authSession.clientId,
      redirect_uri: authSession.redirectUrl,
      code: authCode,
      code_verifier: authSession.codeVerifier,
    };

    const response = await fetchRequest(
      this.resolveUri(config.SSO_TOKEN_PATH),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: createFormBody(reqBody),
        timeout: getApiDefaultTimeout(),
      }
    );

    const tokenResponse = new HttpResponse(
      response.status,
      response.json as TokenResponse
    );

    if (!tokenResponse.isOk() || !tokenResponse?.data?.id_token) {
      throw new AuthError(
        tokenResponse?.data?.error
          ? tokenResponse.data.error
          : ERROR_CODE_TOKEN_REQUEST_FAILED,
        tokenResponse?.data?.error_description
          ? tokenResponse.data.error_description
          : ERROR_MESSAGE_TOKEN_REQUEST_FAILED,
        tokenResponse?.data?.error_uri ? tokenResponse.data.error_uri : "",
        authSession.clientState
      );
    }

    const rawIdToken = tokenResponse.data.id_token;
    const idToken = jwt_decode<IdToken>(rawIdToken);

    // this should not typically happen, but just in case...
    if (!idToken) {
      throw new AuthError(
        ERROR_CODE_JWT_DECODE,
        ERROR_MESSAGE_DECODE_JWT,
        "",
        authSession.clientState
      );
    }

    return { idToken, rawIdToken };
  }

  public async optOutOfOpenpass() {
    const response = await fetchRequest(
      this.resolveUri(config.SSO_OPTOUT_PATH),
      {
        method: "POST",
        timeout: config.API_DEFAULT_TIMEOUT,
        credentials: "include",
      }
    );

    return response;
  }

  private resolveUri(uri: string) {
    const baseUri = this.options.baseUrl || config.SSO_BASE_URL;
    return joinPaths([baseUri, uri]);
  }
}
