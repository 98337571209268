import StorageProvider from "../storage/provider";
import { SignInState } from "../../types/session";
import { config } from "../..//config";

const STORAGE_KEY = `${config.SSO_STORAGE_PREFIX}__client_session`;

/**
 * Class that manages the state during a sign-in / authentication attempt.
 */
export class SignInStateManager {
  private storage: StorageProvider;

  public constructor(storage: StorageProvider) {
    this.storage = storage;
  }

  public add(session: SignInState) {
    this.storage.set(STORAGE_KEY, JSON.stringify(session));
  }

  public get(): SignInState | null {
    const transactionStr = this.storage.get(STORAGE_KEY, "");

    if (!transactionStr) {
      return null;
    }

    return JSON.parse(transactionStr);
  }

  public remove(transaction: SignInState) {
    this.storage.remove(STORAGE_KEY);
  }
}
