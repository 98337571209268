import AuthenticationManager from "./authManager";
import { OpenPassAuthOptions } from "../../types/options";
import { AuthResponse, IdToken } from "../../types/auth";
import {
  isOpenPassSignInRequired,
  openPassSignOut,
  openPassSetSignedIn,
} from "../session/session";

/**
 * An Authentication Manager that manages the OpenPass authentication state using a first party cookie.
 */
export default class OpenPassAuthenticationManager
  implements AuthenticationManager
{
  private readonly options: OpenPassAuthOptions;

  constructor(options: OpenPassAuthOptions) {
    this.options = options;
    this.init();
  }

  private init(): void {
    if (this.options.onAuthChange) {
      this.options.onAuthChange(this.isAuthenticated());
    }
  }

  /**
   * Returns an authentication response that can be checked to determine if the current session is authenticated. The authentication
   * state is managed by an OpenPass first party local cookie.
   */
  isAuthenticated(): AuthResponse {
    return {
      authenticated: isOpenPassSignInRequired(),
    };
  }

  isAuthenticatedAsync(): Promise<AuthResponse> {
    return Promise.resolve(this.isAuthenticated());
  }

  /**
   * Terminates the session by logging the user out by deleting the OpenPass first party local session cookie.
   */
  logout(): void {
    openPassSignOut();
  }

  /**
   * Creates a local session, by creating the OpenPass first party local session cookie.
   * @param idToken
   */
  setAuthenticated(idToken: IdToken): Promise<AuthResponse> {
    openPassSetSignedIn();
    const authResponse = this.isAuthenticated();

    if (this.options.onAuthChange) {
      this.options.onAuthChange(authResponse);
    }

    return this.isAuthenticatedAsync();
  }
}
