import { OpenPassAuthOptions } from "../../types/options";

export function initOpenPassOptions(
  options: OpenPassAuthOptions
): OpenPassAuthOptions {
  return {
    ...options,
    enableUid2Integration: options.enableUid2Integration ?? true,
  };
}
