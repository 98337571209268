import "abortcontroller-polyfill/dist/abortcontroller-polyfill-only";
import { shim } from "promise.prototype.finally";
import OpenPassAuth from "./api";
import {
  LoginResponse,
  LoginOptions,
  PopupLoginOptions,
  OpenPassAuthOptions,
  OpenPassAuthApi,
  AuthResponse,
} from "./types/api";
import { SdkError, AuthError } from "./libs/error/errors";
import { Uid2Identity } from "./types/uid2Types";

shim();

export {
  OpenPassAuth,
  LoginResponse,
  LoginOptions,
  PopupLoginOptions,
  OpenPassAuthOptions,
  OpenPassAuthApi,
  AuthResponse,
  SdkError,
  AuthError,
  Uid2Identity,
};
