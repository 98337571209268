import { OpenPassAuthOptions } from "./types/options";
import { config, getSSOBaseUrl } from "./config";
import { PARAM_CODE_CHALLENGE_METHOD_VALUE } from "./constants";
import { generateCodeChallenge, generateCodeVerifier } from "./libs/auth/pkce";
import { generateStateValue } from "./libs/auth/state";
import {
  buildAuthorizeUrl,
  isAuthRedirectUrl,
  parseAuthRedirectUrlParams,
} from "./libs/auth/url";
import {
  OpenPassApiClient,
  OpenPassTokens,
} from "./libs/client/openPassApiClient";
import {
  ERROR_CODE_INVALID_AUTH_SESSION,
  ERROR_CODE_INVALID_REDIRECT,
} from "./libs/error/codes";
import { AuthError } from "./libs/error/errors";
import {
  ERROR_MESSAGE_INVALID_AUTH_SESSION,
  ERROR_MESSAGE_INVALID_REDIRECT,
} from "./libs/error/messages";
import { getUid2IdentityFromIdToken } from "./libs/uid2/uid2Identity";
import { AuthRedirectUrlParams, AuthSession, IdToken } from "./types/auth";
import { LoginOptions, LoginResponse } from "./types/login";
import AuthenticationManager from "./libs/manager/authManager";
import { SignInStateManager } from "./libs/manager/signInStateManager";

/**
 * Class which handles the redirect authorization flow.
 * To realize this flow this class implements the Authorization Code Flow with Proof Key for Code Exchange (PKCE).
 * The redirect flow involves redirecting to an authorization service, and then handle the login response send via the redirect.
 */
export default class RedirectAuth {
  private readonly authSessionManager: SignInStateManager;
  private readonly openPassClient: OpenPassApiClient;
  private readonly openPassAuthOptions: OpenPassAuthOptions;
  private readonly authenticationManager: AuthenticationManager;

  constructor(
    openPassAuthOptions: OpenPassAuthOptions,
    authSessionManager: SignInStateManager,
    authenticationManager: AuthenticationManager,
    openPassAuthClient: OpenPassApiClient
  ) {
    this.openPassAuthOptions = openPassAuthOptions;
    this.openPassClient = openPassAuthClient;
    this.authSessionManager = authSessionManager;
    this.authenticationManager = authenticationManager;
  }

  /**
   * Initiate the login flow by redirecting to the authorization server
   * @param options   the login options
   */
  public async login(options: LoginOptions) {
    const verifier = generateCodeVerifier();

    const authSession: AuthSession = {
      clientState: options.clientState,
      clientId: this.openPassAuthOptions.clientId,
      redirectUrl: options.redirectUrl,
      codeVerifier: verifier,
      codeChallenge: await generateCodeChallenge(verifier),
      codeChallengeMethod: PARAM_CODE_CHALLENGE_METHOD_VALUE,
      state: generateStateValue(),
    };

    this.authSessionManager.add(authSession);
    const loginUri = buildAuthorizeUrl(
      getSSOBaseUrl(this.openPassAuthOptions.baseUrl),
      config.SSO_AUTHORIZE_PATH,
      authSession
    );
    window.location.href = loginUri;
  }

  /**
   * Determines if a redirect authorization session is in progress, and the current browser url meets the conditions to consider it as a valid redirection from the
   * authorization server for the current login session.
   */
  public isRedirect(): boolean {
    const authSession = this.authSessionManager.get();

    if (!authSession) {
      console.warn(ERROR_MESSAGE_INVALID_AUTH_SESSION);
      return false;
    }

    return isAuthRedirectUrl(
      window.location.href,
      parseAuthRedirectUrlParams(window.location.search),
      authSession.redirectUrl
    );
  }

  /**
   * Determines if the current browser url meets the conditions to consider it as a valid redirection from the
   * authorization server
   *
   * @param redirectUrl   the clients redirect url
   */
  public isRedirectUrl(redirectUrl: string) {
    return isAuthRedirectUrl(
      window.location.href,
      parseAuthRedirectUrlParams(window.location.search),
      redirectUrl
    );
  }

  /**
   * Handles a login redirect and attempts to complete the authorization flow.
   */
  public async handleRedirect(): Promise<LoginResponse> {
    const authSession = this.authSessionManager.get();

    if (!authSession) {
      throw new AuthError(
        ERROR_CODE_INVALID_AUTH_SESSION,
        ERROR_MESSAGE_INVALID_AUTH_SESSION,
        ""
      );
    }

    try {
      if (!this.isRedirect()) {
        throw new AuthError(
          ERROR_CODE_INVALID_REDIRECT,
          ERROR_MESSAGE_INVALID_REDIRECT,
          "",
          authSession.clientState
        );
      }

      const redirectParams = parseAuthRedirectUrlParams(window.location.search);

      if (
        !this.isRedirectUrlValid(authSession, redirectParams) ||
        !redirectParams.code
      ) {
        throw new AuthError(
          redirectParams.error
            ? redirectParams.error
            : ERROR_CODE_INVALID_REDIRECT,
          redirectParams.errorDescription
            ? redirectParams.errorDescription
            : ERROR_MESSAGE_INVALID_REDIRECT,
          redirectParams.errorUri ? redirectParams.errorUri : "",
          authSession.clientState
        );
      }

      const tokens =
        await this.openPassClient.finalizeAuthenticationAndGetTokens(
          redirectParams.code,
          authSession
        );

      return this.completeAuthentication(tokens, authSession);
    } finally {
      this.authSessionManager.remove(authSession);
    }
  }

  protected async completeAuthentication(
    tokens: OpenPassTokens,
    authSession: AuthSession
  ): Promise<LoginResponse> {
    const { idToken, rawIdToken } = tokens;
    const session = await this.authenticationManager.setAuthenticated(idToken);
    const uid2Identity = getUid2IdentityFromIdToken(idToken);

    return {
      authenticated: session.authenticated,
      uid2Identity,
      email: idToken.sub,
      clientState: authSession.clientState,
      idToken: idToken,
      rawIdToken: rawIdToken,
    };
  }

  private isRedirectUrlValid(
    authSession: AuthSession,
    params: AuthRedirectUrlParams
  ): boolean {
    if (!authSession) {
      return false;
    }

    const hasCodeAndStateParams = params.code && params.state;

    if (!hasCodeAndStateParams) {
      return false;
    }

    return authSession.state === params.state;
  }
}
