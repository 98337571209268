import * as consts from "./constants";

export const config = {
  SSO_BASE_URL: "https://api.myopenpass.com", //SSO base uri
  SSO_AUTHORIZE_PATH: "/v1/api/authorize", //SSO authorize endpoint
  SSO_TOKEN_PATH: "/v1/api/token", //SSO token endpoint
  SSO_OPTOUT_PATH: "/v1/api/opt-out", // SSO optout endpoint
  SSO_STORAGE_PREFIX: "__openpass", //prefix for storing a value in storage
  API_DEFAULT_TIMEOUT: 60000, //default timeout for making API requests
  POPUP_RESPONSE_TIMEOUT: 300000, //the timeout to receive a response from the popup
  OPENPASS_LOCAL_COOKIE_EXPIRY_DAYS: 30, // the number of days the OpenPass local cookie is valid
};

export const getSSOBaseUrl = (baseUrl: string | undefined | null) => {
  return baseUrl || config.SSO_BASE_URL;
};

export const getApiDefaultTimeout = () => {
  return config.API_DEFAULT_TIMEOUT;
};

export const getParamGrantTypeValue = () => {
  return consts.PARAM_GRANT_TYPE_VALUE;
};
