/**
 * Returns an appropriate authentication manager based on the OpenPass Sdk configuration options.
 * @param options the OpenPass configuration options
 * @param openPassApiClient  the OpenPass Api Client
 */
import { OpenPassAuthOptions } from "../../types/options";
import { OpenPassApiClient } from "../client/openPassApiClient";
import OpenPassAuthenticationManager from "./authManagerOpenPass";
import UID2AuthenticationManager from "./authManagerUid2";

export default function getAuthenticationManager(
  options: OpenPassAuthOptions,
  openPassApiClient: OpenPassApiClient
) {
  return options.enableUid2Integration
    ? new UID2AuthenticationManager(options, openPassApiClient)
    : new OpenPassAuthenticationManager(options);
}
