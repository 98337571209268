export const ERROR_MESSAGE_INVALID_AUTH_SESSION =
  "Unable to authenticate, a login session may not have being started. Possibly a call to login is required";
export const ERROR_MESSAGE_REDIRECT_URL_NOT_AVAILABLE =
  "Unable to complete login, a valid redirect url was not available";
export const ERROR_MESSAGE_INVALID_REDIRECT =
  "Unable to validate the redirect response";
export const ERROR_MESSAGE_DECODE_JWT = "Unable to decode jwt";
export const ERROR_MESSAGE_TOKEN_REQUEST_FAILED = "Error retrieving token";
export const ERROR_MESSAGE_POPUP_CLOSED =
  "Popup closed, authentication response not available";
export const ERROR_MESSAGE_SET_UID2_IDENTITY =
  "Setting UID2 identity timed out";
export const ERROR_MESSAGE_POPUP_TIMEOUT = "No Response received from popup";
export const ERROR_MESSAGE_INVALID_AUTH_CODE_RESPONSE =
  "Error, invalid authorization code response";
export const ERROR_POPUP_FAILED_TO_OPEN = "Popup window failed to open";
export const ERROR_POPUP_REOPENED = "Popup window was reopened";
export const ERROR_MESSAGE_INVALID_UID2_INTEGRATION_OPTIONS =
  "Error, uid2 integration is enabled but uid2 token creation is possibly disabled. Please check your OpenPass settings";
